import React from "react";

import { IconType } from "react-icons";

const Volume: IconType = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 9.01453C15.3263 9.29858 15.6055 9.63323 15.8375 10.0185C16.2792 10.7518 16.5 11.5518 16.5 12.4185C16.5 13.2685 16.2792 14.056 15.8375 14.781C15.6055 15.1618 15.3263 15.4933 15 15.7752"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 10.4186V14.4186H7.5L11 18.9186V6.41858L7.5 10.4186H4Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M15 5C17.9318 6.18677 20 9.06111 20 12.4185C20 15.7759 17.9318 18.6502 15 19.837"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15 9.01453C15.3263 9.29858 15.6055 9.63323 15.8375 10.0185C16.2792 10.7518 16.5 11.5518 16.5 12.4185C16.5 13.2685 16.2792 14.056 15.8375 14.781C15.6055 15.1618 15.3263 15.4933 15 15.7752"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 10.4186V14.4186H7.5L11 18.9186V6.41858L7.5 10.4186H4Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M15 5C17.9318 6.18677 20 9.06111 20 12.4185C20 15.7759 17.9318 18.6502 15 19.837"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Volume;
