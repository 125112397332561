import { useQuery, QueryResult } from "react-query";

export enum MediaType {
  Image = "image",
  Video = "video",
  Unknown = "unknown",
}

export const checkMediaType = async (url: string): Promise<MediaType> => {
  if (!url) {
    return MediaType.Unknown;
  }

  try {
    const response = await fetch(url, { method: "HEAD" });
    const contentType = response.headers.get("Content-Type");

    if (contentType?.startsWith("image/")) {
      return MediaType.Image;
    } else if (contentType?.startsWith("video/")) {
      return MediaType.Video;
    } else {
      return MediaType.Unknown;
    }
  } catch (error) {
    console.error("Error checking media type:", error);
    throw new Error("Error checking media type");
  }
};

const useMediaType = (url: string): QueryResult<MediaType, Error> => {
  return useQuery(["mediaType", url], () => checkMediaType(url), {
    enabled: !!url,
    staleTime: 1000 * 60 * 5,
  });
};

export default useMediaType;
