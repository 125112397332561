import React, { FC, useMemo, useState } from "react";
import { Helmet } from "react-helmet";

import { useQuery } from "react-query";

import { Box } from "components";
import {
  IModuleContent,
  targetType,
  TModuleCover,
} from "configs/discoveryModule";
import { prefetchMedia } from "utils/prefetch";

import ModuleContent from "./ModuleContent";
import ModuleCover from "./ModuleCover";
import useTracking from "hooks/useTracking";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { Loader } from "../../components/Spinner";

interface IModuleProps {
  onNext: () => void;
  onClose?: () => void;
  moduleContent: IModuleContent[];
  moduleCover: TModuleCover;
  moduleName: string;
  target: targetType;
  showCloseBtn: boolean;
  isReturning: boolean;
}

const Module: FC<IModuleProps> = ({
  onNext,
  onClose,
  moduleContent,
  moduleCover,
  target,
  showCloseBtn,
  moduleName,
  isReturning = false,
}) => {
  const track = useTracking();
  const [showContent, setShowContent] = useState(
    !moduleCover.enable || isReturning,
  );

  const enabledSortedMedia = useMemo(
    () =>
      moduleContent
        .filter(item => item.enable && item.target.includes(target))
        .sort((a, b) => a.order - b.order),
    [moduleContent, target],
  );

  const { data: prefetchedLinkCover = [] } = useQuery(
    "prefetchedLinkCover",
    () =>
      prefetchMedia([
        {
          ...moduleCover,
          src: moduleCover.coverImage || moduleCover.coverVideo || "",
        },
      ]),
    {
      enabled: !!moduleCover,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // Cache for 10 minutes
    },
  );

  const { data: prefetchedLink = [] } = useQuery(
    "prefetchedLink",
    () => prefetchMedia(enabledSortedMedia),
    {
      enabled: !!enabledSortedMedia,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // Cache for 10 minutes
    },
  );

  const handlePrimaryButton = (): void => {
    track({
      eventName: ANALYTICS_EVENTS.MODULE_COVER_PRIMARY_BTN,
      customTrackingProps: { moduleName },
    });
    setShowContent(true);
  };
  const handleSecondaryButton = (): void => {
    track({
      eventName: ANALYTICS_EVENTS.MODULE_COVER_SECONDARY_BTN,
      customTrackingProps: { moduleName },
    });
    onNext();
  };

  const handleClose = (): void => {
    track({
      eventName: ANALYTICS_EVENTS.MODULE_CONTENT_CLOSE,
      customTrackingProps: { moduleName },
    });
    onClose && onClose();
  };

  const handleNextModule = (): void => {
    track({
      eventName: ANALYTICS_EVENTS.NAVIGATE_TO_NEXT_MODULE,
      customTrackingProps: { moduleName, target },
    });
    onNext();
  };

  return (
    <Box width="100%">
      <Helmet>
        {prefetchedLink.map((item, index) => (
          <link
            key={`discovery-module-${index}`}
            rel="prefetch"
            href={item.href}
            as={item.as}
          />
        ))}
        <link
          rel="prefetch"
          href={prefetchedLinkCover[0]?.href}
          as={prefetchedLinkCover[0]?.as}
        />
      </Helmet>
      {prefetchedLinkCover.length > 0 || prefetchedLink.length > 0 ? (
        <>
          {!showContent ? (
            <ModuleCover
              {...prefetchedLinkCover[0]}
              handlePrimaryButton={handlePrimaryButton}
              handleSecondaryButton={handleSecondaryButton}
            />
          ) : (
            <ModuleContent
              mediaItems={prefetchedLink}
              handleNextModule={handleNextModule}
              onClose={
                showCloseBtn && onClose ? () => handleClose() : undefined
              }
              moduleName={moduleName}
              isReturning={isReturning}
            />
          )}
        </>
      ) : (
        <Loader fullScreen />
      )}
    </Box>
  );
};

export default Module;
