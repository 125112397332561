import { usePermissionsState, useUserState } from "../context";
import { isTeamLegacy } from "../components/SeatsCounter/SeatsCounter";
import { USER_IAM_ROLE } from "types/TeamUsersResponse";
import { OVOU_MAIN_SITE_URL } from "../constants";

export const useCanOrderCompanyCard = () => {
  const { currentTeam }: { currentTeam: any } = useUserState();
  const { permission } = usePermissionsState();

  const orderCompanyCardUrl = currentTeam?.id
    ? `${OVOU_MAIN_SITE_URL}/checkout/?add-to-cart=60231&team_id=${currentTeam?.id}`
    : "";

  const orderCardForLegacy = `${OVOU_MAIN_SITE_URL}/checkout/?add-to-cart=7146&team_id=${currentTeam?.id}`;
  const orderCardForActive = `${OVOU_MAIN_SITE_URL}/products/teams-add-on?add-to-cart=68083&team_id=${currentTeam?.id}`;

  const orderAddonCardUrl = currentTeam?.id
    ? isTeamLegacy(permission?.user_iam_role as USER_IAM_ROLE)
      ? orderCardForLegacy
      : orderCardForActive
    : "";

  return {
    orderCompanyCardUrl,
    orderAddonCardUrl,
  };
};
