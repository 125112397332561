import { FC, ReactElement } from "react";

import { AccessTokenGuard } from "./AccessTokenGuard";
import { Box } from "../components";

const OnboardingLayout: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <AccessTokenGuard>
      <Box minHeight="100vh" padding={0} backgroundColor="white" width="100%">
        {children}
      </Box>
    </AccessTokenGuard>
  );
};

export default OnboardingLayout;
