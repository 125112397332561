import React, { FC, useMemo, useState } from "react";

import { SETUP_MODULES } from "configs/setupModules";
import { Flex } from "components";
import useRouter from "hooks/useRouter";
import { targetType } from "configs/discoveryModule";

const SetupModules: FC = () => {
  const router = useRouter();
  const [isReturning, setIsReturning] = useState(false);

  const enabledSortedModules = useMemo(
    () =>
      SETUP_MODULES.filter(item => item.isActive).sort((a, b) => a.id - b.id),
    [],
  );

  const [currentModule, setCurrentModule] = useState(enabledSortedModules[0]);

  const handleNext = (): void => {
    const nextTarget = currentModule.target;
    if (typeof nextTarget === "number") {
      const nextModule = enabledSortedModules.find(
        item => item.id === nextTarget,
      );
      if (nextModule) {
        setCurrentModule(nextModule);
      }
    } else {
      router.push(nextTarget);
    }
  };

  const handleBackModule = (): void => {
    const prevModule = enabledSortedModules.find(
      item => item.id === currentModule.id - 1,
    );
    if (prevModule) {
      setCurrentModule(prevModule);
      setIsReturning(true);
    }
  };

  const ModuleComponent = currentModule.component;
  return (
    <Flex
      justifyContent="center"
      flexDirection="column"
      width="100%"
      alignItems="center"
    >
      <ModuleComponent
        onNext={handleNext}
        onClose={handleNext}
        target={targetType.ONBOARDING}
        handleBackModule={handleBackModule}
        isReturning={isReturning}
      />
    </Flex>
  );
};

export default SetupModules;
