import { FC, useCallback } from "react";
import { Button, Icon, Spinner } from "components";
import { USER_ROLE_STATUS } from "types/TeamUsersResponse";
import { generateMediaPath } from "utils";
import { useFullName } from "hooks";
import defaultPictureSmall from "assets/images/profile-pic-placeholder-sm.svg";

import { useToasts } from "react-toast-notifications";
import { useTeamUsersResendMutation } from "./api/useTeamUsersMutation";
import { useUserState } from "context";
import { IProfileNameCol } from "./types";
import { useCheckPermission, useIsActionAllowed, useTracking } from "hooks";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { MAIN_SITE } from "../../constants";

const ProfileNameCol: FC<IProfileNameCol> = ({
  row,
  setSelectedUserToEdit,
}) => {
  const { addToast } = useToasts();
  const track = useTracking();
  const { currentTeam } = useUserState();
  const teamID = currentTeam?.id as number;
  const {
    email,
    status,
    first_name,
    last_name,
    id,
    user_iam_role,
    profile,
    user_role,
  } = row;
  const { picture = "", handle } = profile || {};
  const isWaiting = user_role?.status === USER_ROLE_STATUS.APPOINTED;
  const fullName = useFullName(row, row.profile);

  const { mutate: fireResendInvitation, isLoading: isResendInvitationLoading } =
    useTeamUsersResendMutation();

  const canEditChangeInvitation = useCheckPermission(
    FEATURE_ACTION.EDIT,
    FEATURES.MANAGE_USERS_CHANGE_EMAIL,
  );
  const canEditResendInvitation = useCheckPermission(
    FEATURE_ACTION.EDIT,
    FEATURES.MANAGE_USERS_RESEND_EMAIL,
  );
  const { handleAction } = useIsActionAllowed();

  const handleResendInvitation = useCallback(() => {
    handleAction(
      canEditResendInvitation,
      FEATURES.MANAGE_USERS_RESEND_EMAIL,
      () => {
        fireResendInvitation(
          {
            teamId: teamID,
            userId: id,
          },
          {
            onSuccess: () => {
              addToast("Invitation sent successfully.", {
                appearance: "success",
              });
              track({
                eventName: ANALYTICS_EVENTS.MANAGE_USERS_RESEND_INVITE,
                customTrackingProps: {
                  userId: id,
                  userEmail: email,
                },
              });
            },
            onError: error => {
              addToast(error as string, {
                appearance: "error",
              });
            },
          },
        );
      },
    );
  }, [canEditResendInvitation, teamID, id, email]);

  const handleChangeInvitation = useCallback(() => {
    handleAction(
      canEditChangeInvitation,
      FEATURES.MANAGE_USERS_CHANGE_EMAIL,
      () => {
        setSelectedUserToEdit({
          id,
          email,
          first_name,
          last_name,
          status,
          user_iam_role,
        });
        track({
          eventName: ANALYTICS_EVENTS.MANAGE_USERS_OPEN_CHANGE_INVITATION_MODAL,
        });
      },
    );
  }, [canEditChangeInvitation, id]);

  return (
    <div className="flex items-center w-full">
      {isWaiting ? (
        <div
          style={{ minHeight: "64px" }}
          className="flex items-center justify-center w-12"
        >
          <Icon name="clock" />
        </div>
      ) : (
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${MAIN_SITE}/${handle}`}
          >
            <img
              className="rounded-xl w-12 h-16 block"
              src={picture ? generateMediaPath(picture) : defaultPictureSmall}
              alt={`${fullName}'s Profile Pic`}
            />
          </a>
        </div>
      )}
      <div className="ml-6 flex items-end">
        {isWaiting ? (
          <span className="text-black font-semibold">
            Waiting for <br /> {email}
          </span>
        ) : (
          <div>
            {fullName?.trim() && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${MAIN_SITE}/${handle}`}
                className="inline-flex"
              >
                <span className="block text-black text-lg font-bold">
                  {fullName}
                </span>
              </a>
            )}
            {email && (
              <span className="block text-custom-gray-500 text-sm mt-1">
                {email}
              </span>
            )}
          </div>
        )}
        {isWaiting && (
          <>
            <Button
              variant="white"
              size="small"
              className="pl-2 pr-2 pt-1 pb-1 ml-2 sm:text-xs"
              onClick={handleChangeInvitation}
              block
            >
              Change
            </Button>
            <Button
              block
              variant="white"
              size="small"
              className="pl-2 pr-2 pt-1 pb-1 ml-2 sm:text-xs"
              onClick={handleResendInvitation}
            >
              {isResendInvitationLoading ? <Spinner size={5} /> : "Resend"}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileNameCol;
function parseAxiosError(error: unknown): import("react").ReactNode {
  throw new Error("Function not implemented.");
}
