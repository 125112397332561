import React, { FC } from "react";

import { Box } from "components";

interface IGradientOverlayProps {
  gradient: string;
  height: string;
  position?: "top" | "bottom" | "left" | "right";
  children?: React.ReactNode;
}

const GradientOverlay: FC<IGradientOverlayProps> = ({
  gradient,
  position = "bottom",
  height,
  children,
}) => {
  const styles: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    height: height,
    zIndex: 1,
    ...(position === "bottom" && { bottom: 0 }),
    ...(position === "top" && { top: 0 }),
    ...(position === "left" && { left: 0 }),
    ...(position === "right" && { right: 0 }),
  };
  return (
    <Box style={styles} bgGradient={gradient}>
      {children}
    </Box>
  );
};

export default GradientOverlay;
