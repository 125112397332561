import { FC, useEffect, useState } from "react";
import {
  Box,
  MultiEmailInput,
  SeatsCounter,
  Heading,
  Text,
  CButton,
  NewModal,
  Spinner,
} from "components";

import { theme } from "ovou-ui";
import { usePermissionsState, useUser } from "context";
import { OVOU_MAIN_SITE_URL, STRIPE_URL, TEXTS } from "../../constants";
import { useMultiEmail } from "hooks";
import { IAssignSeats } from "types/IAssignSeatsModal";
import { isTeamLegacy } from "../../components/SeatsCounter/SeatsCounter";
import { USER_IAM_ROLE } from "types/TeamUsersResponse";

const AssignSeatsModal: FC<IAssignSeats> = ({
  teamUsers,
  isOpen,
  onClose,
  handleInviteUsers,
  isLoading,
}) => {
  const [{ currentTeam }] = useUser() as any;
  const { permission } = usePermissionsState();
  const {
    num_seats: numSeats,
    assigned_seats: assignedSeatsCount,
    pending_seats: pendingSeats,
  } = currentTeam;

  const userList = teamUsers?.map(({ email }) => email);
  const availableSeats =
    parseInt(numSeats) - parseInt(assignedSeatsCount) - parseInt(pendingSeats);

  const { setEmails, ...multiEmailInputProps } = useMultiEmail({
    limit: availableSeats,
    userList,
  });
  const { isLimitExceeded } = multiEmailInputProps;

  useEffect(() => {
    if (!isOpen) {
      setEmails([]);
    }
  }, [isOpen]);

  const onCloseModal = (): void => {
    setEmails([]);
    onClose && onClose();
  };

  const handleSubmit = (emails: string[]): void => {
    if (handleInviteUsers) {
      handleInviteUsers(emails);
    }
  };

  const message = isTeamLegacy(permission?.user_iam_role as USER_IAM_ROLE)
    ? TEXTS.NO_AVAILABLE_CARDS
    : TEXTS.NO_AVAILABLE_SEAT;

  return (
    <NewModal
      isOpen={isOpen}
      onClose={onCloseModal}
      title={
        <>
          <Heading as="h3" fontSize="2xl" fontWeight="extrabold" color="black">
            Invite Users
          </Heading>
          {Boolean(availableSeats) && (
            <>
              <Text
                fontSize="16px"
                fontWeight="400"
                marginTop="8px"
                marginBottom="16px"
                color={theme.palette.ui.greys.grey1}
              >
                Type or paste the emails you want to invite.
              </Text>
              <SeatsCounter
                showAvailable={false}
                assignedSeats={assignedSeatsCount || 0}
                pendingSeats={pendingSeats || 0}
                numberOfSeats={numSeats || 0}
                availableSeats={availableSeats}
              />
            </>
          )}
        </>
      }
      modalBody={
        <>
          <Box>
            {Boolean(availableSeats) ? (
              <MultiEmailInput
                {...multiEmailInputProps}
                onSubmit={emails => {
                  if (Boolean(emails.length)) {
                    handleSubmit(emails);
                  }
                }}
              >
                <CButton
                  type="submit"
                  width="100%"
                  height="56px"
                  isDisabled={
                    Boolean(
                      multiEmailInputProps?.emailsThatAlreadyExist?.length,
                    ) || isLimitExceeded
                  }
                  _focus={{ outline: "none" }}
                >
                  {isLoading ? <Spinner color="white" /> : "Invite User"}
                </CButton>
              </MultiEmailInput>
            ) : (
              <Text fontSize="16px" color={theme.palette.ui.greys.grey1}>
                {message}
              </Text>
            )}
          </Box>
        </>
      }
      modalFooter={
        <>
          {!Boolean(availableSeats) && (
            <>
              {isTeamLegacy(permission?.user_iam_role as USER_IAM_ROLE) ? (
                <CButton
                  width="100%"
                  height="56px"
                  as="a"
                  href={`${OVOU_MAIN_SITE_URL}/checkout/?add-to-cart=7146&team_id=${currentTeam?.id}`}
                  target="_blank"
                  _focus={{ outline: "none" }}
                >
                  Add More Cards
                </CButton>
              ) : (
                <CButton
                  width="100%"
                  height="56px"
                  as="a"
                  href={STRIPE_URL}
                  target="_blank"
                  _focus={{ outline: "none" }}
                >
                  Add Seats
                </CButton>
              )}
            </>
          )}
        </>
      }
    />
  );
};

export default AssignSeatsModal;
