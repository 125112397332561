import { DIGITAL_OCEAN_URL } from "../constants";
import { checkMediaType } from "hooks/useMediaType";
import { generateMediaPath } from "./generateMediaPath";

interface IMediaItem {
  src: string;
  poster: string;
}

interface IMediaItemWithPrefetch extends IMediaItem {
  href: string;
  as: string;
  blobUrl: string;
}
const isBlobSupported = (): boolean => {
  if (typeof window === "undefined") {
    return false;
  }

  return !!(window.URL && window.URL.createObjectURL);
};

export const prefetchMedia = async <T extends IMediaItem>(
  mediaItems: T[],
): Promise<(T & IMediaItemWithPrefetch)[]> => {
  const supportsBlob = isBlobSupported();

  const prefetchedLinks = await Promise.all(
    mediaItems.map(async item => {
      const href = generateMediaPath(item.src, DIGITAL_OCEAN_URL);
      const mediaType = await checkMediaType(href);
      const poster = generateMediaPath(item.poster, DIGITAL_OCEAN_URL);
      let videoBlobUrl = "";
      let posterBlobUrl = "";
      if (supportsBlob) {
        const [videoBlob, posterBlob] = await Promise.all([
          fetch(href).then(response => {
            if (!response.ok)
              throw new Error(`Video fetch failed: ${response.status}`);
            return response.blob();
          }),
          fetch(poster).then(response => {
            if (!response.ok)
              throw new Error(`Poster fetch failed: ${response.status}`);
            return response.blob();
          }),
        ]);
        videoBlobUrl = URL.createObjectURL(videoBlob);
        posterBlobUrl = URL.createObjectURL(posterBlob);
      }

      return {
        ...item,
        href,
        as: mediaType,
        blobUrl: videoBlobUrl || href,
        poster: posterBlobUrl || poster,
      };
    }),
  );
  return prefetchedLinks;
};
