import React, { useCallback } from "react";

import { theme } from "ovou-ui";

import { Box, Flex, Image } from "components";
import Close from "../../components/Icon/svg/CloseButton";
import Volume from "../../components/Icon/svg/Volume";
import VolumeMute from "../../components/Icon/svg/VolumeMute";
import { IModuleContent } from "configs/discoveryModule";
import { MediaType } from "hooks/useMediaType";

const videoStyles: React.CSSProperties = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 0,
};

interface IMediaRendererProps {
  currentMedia: IModuleContent;
  videoRef: React.RefObject<HTMLVideoElement>;
  isMuted: boolean;
  handleNext: () => void;
  toggleSound: (event: React.MouseEvent | React.TouchEvent) => void;
  onClose?: () => void;
  mediaType: MediaType;
}

const MediaRenderer: React.FC<IMediaRendererProps> = ({
  currentMedia,
  videoRef,
  isMuted,
  handleNext,
  toggleSound,
  onClose,
  mediaType,
}) => {
  const handleMouse = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
  }, []);
  return (
    <>
      <Box
        width="100%"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor={theme.palette.brand.primary.black}
      >
        {mediaType === MediaType.Video ? (
          <video
            style={videoStyles}
            ref={videoRef}
            muted={isMuted}
            autoPlay
            playsInline
            onEnded={handleNext}
            data-testid="media-video"
            {...{ "webkit-playsinline": "true", "x-webkit-airplay": "allow" }}
            preload="auto"
            poster={currentMedia.poster}
            src={currentMedia.blobUrl}
          />
        ) : (
          <Image
            src={currentMedia.blobUrl || ""}
            width="100%"
            height="100%"
            alt={currentMedia.headline || ""}
          />
        )}
      </Box>

      <Flex position="absolute" top="32px" right="0.65rem">
        {mediaType === MediaType.Video && currentMedia.sound_enabled && (
          <Box
            onClick={toggleSound}
            zIndex={2}
            aria-label="Toggle Sound"
            onMouseDown={handleMouse}
            onMouseUp={handleMouse}
            onTouchStart={handleMouse}
            onTouchEnd={handleMouse}
            cursor="pointer"
          >
            {isMuted ? <VolumeMute /> : <Volume />}
          </Box>
        )}

        {onClose && (
          <Box
            onClick={onClose}
            zIndex={2}
            aria-label="Close"
            onMouseDown={handleMouse}
            onMouseUp={handleMouse}
            marginLeft="16px"
          >
            <Close
              color={theme.palette.brand.primary.white}
              size={24}
              data-testid="close-icon"
            />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default MediaRenderer;
