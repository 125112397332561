/* eslint-disable react/no-children-prop */
import { ReactElement, forwardRef } from "react";

import { useBoolean } from "@chakra-ui/hooks";
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from "@chakra-ui/input";
import { Box, Text } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { FiEye, FiEyeOff, FiX } from "react-icons/fi";
import useMeasure from "react-use-measure";

import { Label } from "../Label/Label";
import { useInput } from "./useInput";

export interface ITextInputProps extends Omit<InputProps, "form"> {
  id: string;
  label?: string;
  variant?: never;
  errorMessage?: string;
  helperMessage?: string;
  leftIcon?: ReactElement;
  rightElement?: ReactElement | null;
  preVal?: string;
  onClear?: () => void;
  maxLength?: number;
  pt?: number;
}

const TextInput = forwardRef<
  HTMLInputElement,
  ITextInputProps
>(
  (
    {
      id,
      errorMessage,
      label,
      onChange,
      leftIcon,
      rightElement,
      type,
      preVal,
      onFocus,
      onBlur,
      isDisabled,
      onClear,
      value,
      maxLength,
      pt = 4,
      helperMessage,
      ...restProps
    },
    ref,
  ) => {
    const [leftElRef, leftElRefBounds] = useMeasure();
    const [rightElRef, rightElRefBounds] = useMeasure();

    const { handleBlur, handleChange, handleFocus, isActive, isFocused } =
      useInput({
        onBlur,
        onChange,
        onFocus,
        maxLength,
      });

    const isInvalid = !!errorMessage || restProps.isInvalid;
    const hasLeftElement = leftIcon || preVal;
    const hasRightElement = rightElement || onClear;
    const shouldFloatLabel = !hasLeftElement;
    return (
      <FormControl
        sx={{ position: "relative", pt }}
        id={id}
        isInvalid={isInvalid}
      >
        {label && (
          <Label
            label={label}
            isActive={isActive || !!value || !!restProps.placeholder}
            isFocused={isFocused}
            isInvalid={isInvalid}
            id={id}
            isFloating={shouldFloatLabel}
            isDisabled={isDisabled}
            subLabel={
              maxLength ? (
                <Text color="brand.gray.400">
                  {(value || "")?.toString()?.length} / {maxLength}
                </Text>
              ) : <></>
            }
          />
        )}
        <InputGroup
          sx={{
            input: {
              pl: hasLeftElement
                ? leftElRefBounds.width + (preVal ? 0 : 5)
                : "0",
              pr: hasRightElement ? rightElRefBounds.width + 5 : "0",
            },
          }}
        >
          <InputLeftElement
            ref={leftElRef}
            width="auto"
            pointerEvents="none"
            color="brand.gray.400"
            children={leftIcon || <Text as="span">{preVal}</Text>}
          />
          <Box
            w="full"
            position="relative"
          >
            <ChakraInput
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              id={id}
              ref={ref}
              type={type}
              isDisabled={isDisabled}
              value={value}
              aria-label={label}
              {...restProps}
            />

          </Box>
          <InputRightElement
            ref={rightElRef}
            width="auto"
            children={
              onClear ? (
                value ? (
                  <Box onClick={onClear}>
                    <FiX />
                  </Box>
                ) : null
              ) : (
                rightElement
              )
            }
          />
        </InputGroup>
        {!errorMessage && helperMessage ? (
          <FormHelperText color="brand.gray.400" fontSize="sm" fontWeight="400">
            {helperMessage}
          </FormHelperText>
        ) : (
          <FormErrorMessage
            color="brand.red.500"
            fontSize="sm"
            fontWeight="400"
          >
            {errorMessage}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  },
);

TextInput.displayName = "TextInput";

export default TextInput;
