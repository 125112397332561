import { useEffect, useState, useRef, useCallback } from "react";

import { Box, Flex } from "components";
import GradientOverlay from "../../components/GradientOverlay";
import ProgressBar from "../../components/ProgressBar";
import {
  DISCOVERY_MODULE,
  IModuleContent,
  MODULE_COVER,
} from "configs/discoveryModule";
import { HOLD_DURATION, MAX_PERCENTAGE } from "../../constants";
import { MediaType } from "hooks/useMediaType";

import MediaDetails from "./MediaDetails";
import MediaRenderer from "./MediaRenderer";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

interface IModuleContentProps {
  mediaItems: IModuleContent[];
  onClose?: () => void;
  handleNextModule: () => void;
  moduleName: string;
  isReturning: boolean;
}

const ModuleContent: React.FC<IModuleContentProps> = ({
  mediaItems,
  onClose,
  handleNextModule,
  moduleName,
  isReturning = false,
}) => {
  const track = useTracking();
  const [currentMediaIndex, setCurrentMediaIndex] = useState(
    isReturning ? mediaItems.length - 1 : 0,
  );
  const [progress, setProgress] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const holdTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [playbackState, setPlaybackState] = useState({
    isPaused: false,
    isMuted: true,
    isHolding: false,
  });

  const currentMedia = mediaItems[currentMediaIndex];
  const currentMediaType = currentMedia?.as;

  useEffect(() => {
    if (playbackState.isPaused || isTransitioning) return;

    const updateProgress = (): void => {
      setProgress(prev => {
        if (prev < MAX_PERCENTAGE) {
          return Math.min(prev + 1, MAX_PERCENTAGE);
        } else {
          if (currentMediaType === MediaType.Image) handleNext(); // When progress reaches 100, go to the next media.
          return prev;
        }
      });
    };

    const interval = setInterval(
      updateProgress,
      currentMedia.duration / MAX_PERCENTAGE,
    );

    return () => clearInterval(interval);
  }, [playbackState.isPaused, isTransitioning, currentMedia.duration]);

  // Play the video whenever the currentMediaIndex changes
  useEffect(() => {
    setProgress(0); // Reset progress when media changes
    const video = videoRef.current;
    if (video && currentMediaType === MediaType.Video) {
      video.poster = currentMedia.poster;
      video.src = currentMedia.blobUrl || "";
      video.play();
    }
  }, [currentMediaIndex, currentMedia, currentMediaType]);

  const handleNext = useCallback((): void => {
    if (isTransitioning) return;

    if (currentMediaIndex < mediaItems.length - 1) {
      track({
        eventName: ANALYTICS_EVENTS.MODULE_CONTENT_NEXT,
        customTrackingProps: {
          moduleName,
          currentMedia: currentMedia.src,
        },
      });
      setIsTransitioning(true);
      setCurrentMediaIndex(prev => prev + 1);
      setIsTransitioning(false);
    } else {
      handleNextModule();
    }
  }, [isTransitioning, currentMediaIndex, mediaItems.length, handleNextModule]);

  const handlePrev = useCallback((): void => {
    if (isTransitioning) return;
    if (currentMediaIndex > 0) {
      track({
        eventName: ANALYTICS_EVENTS.MODULE_CONTENT_PREV,
        customTrackingProps: {
          moduleName,
          currentMedia: currentMedia.src,
        },
      });
      setIsTransitioning(true);
      setCurrentMediaIndex(prev => prev - 1);
      setIsTransitioning(false);
    }
  }, [isTransitioning, currentMediaIndex]);

  const toggleSound = useCallback(
    (event: React.MouseEvent | React.TouchEvent): void => {
      event.stopPropagation();
      event.preventDefault();
      track({
        eventName: ANALYTICS_EVENTS.MODULE_CONTENT_TOGGLE_SOUND,
        customTrackingProps: {
          moduleName,
          currentMedia: currentMedia.src,
          isMuted: !playbackState.isMuted,
        },
      });
      setPlaybackState(prev => {
        if (videoRef.current) {
          videoRef.current.muted = !prev.isMuted;
        }
        return { ...prev, isMuted: !prev.isMuted };
      });
    },
    [],
  );

  const handleStart = useCallback((): void => {
    setPlaybackState(prev => ({ ...prev, isPaused: true }));
    if (currentMediaType === MediaType.Video && videoRef.current) {
      videoRef.current.pause();
    }
    // Set timeout to determine if it's a hold
    const timeout = setTimeout(() => {
      setPlaybackState(prev => ({ ...prev, isHolding: true })); // Mark as holding if timeout is reached
    }, HOLD_DURATION);
    holdTimeoutRef.current = timeout;
  }, [currentMediaType, videoRef]);

  const handleEnd = useCallback(
    (event: React.MouseEvent | React.TouchEvent): void => {
      event.preventDefault();
      if (holdTimeoutRef.current) {
        clearTimeout(holdTimeoutRef.current);
        holdTimeoutRef.current = null;

        // Only proceed if it was a click, not a hold
        if (!playbackState.isHolding) {
          // Check if mouse is still over the left or right half of the screen
          const clientX =
            "touches" in event
              ? event.changedTouches[0].clientX
              : event.clientX;

          const isRightHalf = clientX > window.innerWidth / 2;

          if (isRightHalf) {
            handleNext();
          } else {
            handlePrev();
          }
        }
      }
      setPlaybackState(prev => ({
        ...prev,
        isPaused: false,
        isHolding: false,
      }));
      if (currentMediaType === MediaType.Video && videoRef.current) {
        videoRef.current.play();
      }
    },
    [
      holdTimeoutRef,
      playbackState.isHolding,
      handleNext,
      handlePrev,
      currentMediaType,
    ],
  );

  return (
    <Box
      width="100%"
      h="100dvh"
      position="relative"
      onMouseDown={handleStart}
      onMouseUp={handleEnd}
      onTouchStart={handleStart}
      onTouchEnd={handleEnd}
    >
      <GradientOverlay
        height="104px"
        gradient={DISCOVERY_MODULE.gradient}
        position="top"
      />
      <Flex position="absolute" top="0" w="100%" zIndex={10} p={4}>
        {mediaItems.map((_, index) => (
          <ProgressBar
            key={index}
            progress={
              index === currentMediaIndex
                ? progress
                : index < currentMediaIndex
                ? 100
                : 0
            }
            isLast={index === mediaItems.length - 1}
          />
        ))}
      </Flex>

      <MediaRenderer
        currentMedia={currentMedia}
        videoRef={videoRef}
        isMuted={playbackState.isMuted}
        handleNext={handleNext}
        toggleSound={toggleSound}
        onClose={onClose}
        mediaType={currentMediaType as MediaType}
      />

      <GradientOverlay
        height="200px"
        gradient={MODULE_COVER.gradient}
        position="bottom"
      />
      <MediaDetails headline={currentMedia.headline} desc={currentMedia.desc} />
    </Box>
  );
};

export default ModuleContent;
