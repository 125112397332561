import React, { FC, useState } from "react";

import { useBoolean, Button } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import { Text, Box, Flex, NewModal } from "components";
import { useCheckPermission } from "hooks";
import { FEATURE_ACTION } from "ovou-ui";
import { FEATURES } from "ovou-ui";

import useIntegrationService from "./useIntegrationService";
import { Integration, IntegrationStatus } from "./services/IIntegrationService";
import TaskHistory from "./TaskHistory";

const IntegrationMenu: FC<{ integrations: Integration[] }> = ({
  integrations,
}) => {
  const { showConfigurationMenu } = useIntegrationService();

  const [selectedIntegrationId, setSelectedIntegrationId] = useState("");

  const canConfigure = useCheckPermission(
    FEATURE_ACTION.VIEW,
    FEATURES.INTEGRATIONS,
  );
  if (!integrations.length) {
    return null;
  }
  const handleOpenTaskHistory = (id: string): void => {
    setSelectedIntegrationId(id);
  };
  return (
    <>
      {integrations.map(integration => {
        return (
          <Box
            key={integration.id}
            marginBottom="6"
            padding="4"
            borderRadius="10px"
            border={`1px solid ${theme.palette.ui.greys.grey4}`}
          >
            <Box>
              <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                  <Box
                    border="1px solid #e2e2e2"
                    padding="13px 10px"
                    borderRadius="10px"
                  >
                    <img src={integration.icon} width={25} height={25} />
                  </Box>
                  <Text marginLeft="3" fontSize={20} fontWeight={800}>
                    {integration.name}
                  </Text>
                </Flex>
                <Text
                  fontSize={14}
                  fontWeight="600"
                  color={getIntegrationStatusColor(integration.status)}
                >
                  {getIntegrationStatusText(integration.status)}
                </Text>
              </Flex>
              <Text
                marginY="1.5rem"
                fontSize={14}
                color={theme.palette.ui.greys.grey1}
              >
                Native integration with {integration.name}
              </Text>

              <Button
                width="full"
                marginTop={4}
                onClick={() => showConfigurationMenu(integration.id)}
                isDisabled={!canConfigure}
              >
                Configure
              </Button>
            </Box>
            <Flex
              justifyContent="center"
              mt="16px"
              fontWeight="800"
              padding="8px"
            >
              <Text
                fontSize={14}
                color={theme.palette.brand.primary.orange}
                onClick={() => handleOpenTaskHistory(integration.id)}
                cursor="pointer"
              >
                View Your Task History
              </Text>
            </Flex>
          </Box>
        );
      })}
      {selectedIntegrationId && (
        <TaskHistory
          integration={selectedIntegrationId}
          isOpen={!!selectedIntegrationId}
          onClose={() => setSelectedIntegrationId("")}
        />
      )}
    </>
  );
};

const getIntegrationStatusColor = (
  integrationStatus: IntegrationStatus,
): string => {
  switch (integrationStatus) {
    case IntegrationStatus.CONNECTED: {
      return theme.palette.ui.states.success;
    }
    case IntegrationStatus.WRONG_CONFIGURATION:
    case IntegrationStatus.NOT_CONFIGURED: {
      return theme.palette.ui.cta.red;
    }
    case IntegrationStatus.DISABLED:
    case IntegrationStatus.DISCONNECTED: {
      return theme.palette.ui.greys.grey2;
    }
  }
};

const getIntegrationStatusText = (
  integrationStatus: IntegrationStatus,
): string => {
  switch (integrationStatus) {
    case IntegrationStatus.CONNECTED: {
      return "Connected";
    }
    case IntegrationStatus.WRONG_CONFIGURATION: {
      return "Wrong configuration";
    }
    case IntegrationStatus.NOT_CONFIGURED: {
      return "Not configured";
    }
    case IntegrationStatus.DISABLED: {
      return "Disabled";
    }
    case IntegrationStatus.DISCONNECTED: {
      return "Not connected";
    }
  }
};

export default IntegrationMenu;
