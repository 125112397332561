import { getEnvVariable } from "../utils";

export { TEXTS } from "./Texts";
export { VALIDATION_MESSAGES } from "./validationMessages";
export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";

export const ERROR = {
  NETWORK: {
    TYPE: "Network Error",
    MESSAGE: "An unknown network error has occurred.",
  },
};

export const LOCAL_STORAGE_KEYS = {
  FEATURE_FLAGS: "FEATURE_FLAGS",
};
export const API_URL = getEnvVariable("REACT_APP_API_URL");
export const MEDIA_URL = getEnvVariable("REACT_APP_MEDIA_URL");
export const ENV_URL = getEnvVariable("APP_OVOU_MAIN_ENV_URL");
export const MAIN_SITE = getEnvVariable("REACT_APP_SITE_URL");
export const ENVIRONMENT = getEnvVariable("REACT_APP_APP_ENV", "development");
export const PROJECT_ID = getEnvVariable("REACT_APP_PROJECT_ID");
export const PROJECT_ACCESS_TOKEN = getEnvVariable(
  "REACT_APP_PROJECT_ACCESS_TOKEN",
);
export const API_MOCKING = getEnvVariable("REACT_APP_API_MOCKING");
export const UNLEASH_FRONT_END = getEnvVariable("REACT_APP_UNLEASH_FRONT_END");
export const UNLEASH_CLIENT_KEY = getEnvVariable(
  "REACT_APP_UNLEASH_CLIENT_KEY",
);
export const STRIPE_URL = getEnvVariable("REACT_APP_STRIPE_URL");
export const GA_TRACKING_ID = getEnvVariable("REACT_APP_GA_TRACKING_ID");
export const SEGMENT_WRITE_KEY = getEnvVariable("REACT_APP_SEGMENT_WRITE_KEY");
export const BUSINESS_SITE_URL = getEnvVariable("REACT_APP_BUSINESS_SITE_URL");
export const PARAGON_PROJECT_ID = getEnvVariable(
  "REACT_APP_PARAGON_PROJECT_ID",
);

// Datadog
export const DATADOG_APPLICATION_ID = getEnvVariable(
  "REACT_APP_DATADOG_APPLICATION_ID",
);
export const DATADOG_CLIENT_TOKEN = getEnvVariable(
  "REACT_APP_DATADOG_CLIENT_TOKEN",
);
export const DATADOG_SITE = getEnvVariable("REACT_APP_DATADOG_SITE");
export const HOTJAR_HJID = getEnvVariable("REACT_APP_HJID");
export const HOTJAR_HJSV = getEnvVariable("REACT_APP_HJSV");
export const MAIN_SITE_URL = getEnvVariable("REACT_APP_MAIN_SITE_URL");
export const ZAPIER_CLIENT_ID = getEnvVariable("REACT_APP_ZAPIER_CLIENT_ID");
export const OVOU_MAIN_SITE_URL = getEnvVariable(
  "REACT_APP_OVOU_MAIN_SITE_URL",
);
export const DIGITAL_OCEAN_URL = getEnvVariable("REACT_APP_DIGITAL_OCEAN_URL");

export const ACTION = {
  EDIT: "edit",
  ADD: "add",
  PRE_ADD: "preAdd",
  PRE_EDIT: "preEdit",
};

// Zapier

export const LOCATION = "location";
export const MANAGE_USERS_ROUTE = "/manage-users";

export const LOGO_MAX_WIDTH = 512;
export const COVER_MAX_WIDTH = 1600;

export const PROFILE_REPRESENTATIVE_STATE = "is_representative";

export const HIGHER_ENVIRONMENT = ["sandbox", "production"];

export const NO_NEED_WORKFLOW_CONFIG = ["monday.com"];

export const HOLD_DURATION = 300;
export const MAX_PERCENTAGE = 100;
