import React from "react";

import { IconType } from "react-icons";

const VolumeMute: IconType = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 4L21 21" stroke="white" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9863 16.572C20.6338 15.3296 21 13.917 21 12.4185C21 8.63986 18.6717 5.40744 15.3752 4.07307C14.8633 3.86584 14.2803 4.11286 14.0731 4.62479C13.8658 5.13672 14.1129 5.71972 14.6248 5.92694C17.192 6.96612 19 9.48236 19 12.4185C19 13.3557 18.8158 14.25 18.4815 15.0673L19.9863 16.572ZM17.3045 13.8903C17.4346 13.4171 17.5 12.9257 17.5 12.4185C17.5 11.3724 17.2308 10.3936 16.6941 9.50255C16.4104 9.03147 16.0647 8.61552 15.6566 8.26027C15.24 7.89765 14.6084 7.94139 14.2457 8.35795C13.8831 8.77452 13.9269 9.40617 14.3434 9.76879C14.5879 9.98165 14.8005 10.235 14.9809 10.5344C15.2681 11.0112 15.4357 11.5194 15.4848 12.0705L17.3045 13.8903ZM14.9306 14.3448L16.3644 15.7786C16.1531 16.0536 15.9163 16.3051 15.6538 16.5319C15.236 16.893 14.6045 16.8469 14.2434 16.4291C13.8823 16.0112 13.9283 15.3797 14.3462 15.0186C14.5676 14.8272 14.7624 14.6039 14.9306 14.3448ZM12 8.58577V6.41858C12 6.00197 11.7417 5.62901 11.3517 5.48248C10.9617 5.33595 10.5218 5.44655 10.2474 5.76008L9.74664 6.33241L12 8.58577ZM8.4267 7.8409L7.04623 9.41858H4C3.44772 9.41858 3 9.8663 3 10.4186V14.4186C3 14.9709 3.44772 15.4186 4 15.4186H7.01092L10.2106 19.5325C10.4729 19.8697 10.9205 20.0031 11.3245 19.8645C11.7286 19.7258 12 19.3458 12 18.9186V11.4142L10 9.4142V16.004L8.28935 13.8046C8.0999 13.5611 7.80859 13.4186 7.5 13.4186H5V11.4186H7.5C7.78836 11.4186 8.06269 11.2941 8.25258 11.0771L9.84406 9.25826L8.4267 7.8409ZM17.4266 16.8408L18.8463 18.2605C17.9142 19.3516 16.726 20.2171 15.3752 20.7639C14.8633 20.9711 14.2803 20.7241 14.0731 20.2122C13.8658 19.7003 14.1129 19.1173 14.6248 18.91C15.726 18.4643 16.6875 17.7467 17.4266 16.8408Z"
        fill="white"
      />
      <path d="M4 4L21 21" stroke="white" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9863 16.572C20.6338 15.3296 21 13.917 21 12.4185C21 8.63986 18.6717 5.40744 15.3752 4.07307C14.8633 3.86584 14.2803 4.11286 14.0731 4.62479C13.8658 5.13672 14.1129 5.71972 14.6248 5.92694C17.192 6.96612 19 9.48236 19 12.4185C19 13.3557 18.8158 14.25 18.4815 15.0673L19.9863 16.572ZM17.3045 13.8903C17.4346 13.4171 17.5 12.9257 17.5 12.4185C17.5 11.3724 17.2308 10.3936 16.6941 9.50255C16.4104 9.03147 16.0647 8.61552 15.6566 8.26027C15.24 7.89765 14.6084 7.94139 14.2457 8.35795C13.8831 8.77452 13.9269 9.40617 14.3434 9.76879C14.5879 9.98165 14.8005 10.235 14.9809 10.5344C15.2681 11.0112 15.4357 11.5194 15.4848 12.0705L17.3045 13.8903ZM14.9306 14.3448L16.3644 15.7786C16.1531 16.0536 15.9163 16.3051 15.6538 16.5319C15.236 16.893 14.6045 16.8469 14.2434 16.4291C13.8823 16.0112 13.9283 15.3797 14.3462 15.0186C14.5676 14.8272 14.7624 14.6039 14.9306 14.3448ZM12 8.58577V6.41858C12 6.00197 11.7417 5.62901 11.3517 5.48248C10.9617 5.33595 10.5218 5.44655 10.2474 5.76008L9.74664 6.33241L12 8.58577ZM8.4267 7.8409L7.04623 9.41858H4C3.44772 9.41858 3 9.8663 3 10.4186V14.4186C3 14.9709 3.44772 15.4186 4 15.4186H7.01092L10.2106 19.5325C10.4729 19.8697 10.9205 20.0031 11.3245 19.8645C11.7286 19.7258 12 19.3458 12 18.9186V11.4142L10 9.4142V16.004L8.28935 13.8046C8.0999 13.5611 7.80859 13.4186 7.5 13.4186H5V11.4186H7.5C7.78836 11.4186 8.06269 11.2941 8.25258 11.0771L9.84406 9.25826L8.4267 7.8409ZM17.4266 16.8408L18.8463 18.2605C17.9142 19.3516 16.726 20.2171 15.3752 20.7639C14.8633 20.9711 14.2803 20.7241 14.0731 20.2122C13.8658 19.7003 14.1129 19.1173 14.6248 18.91C15.726 18.4643 16.6875 17.7467 17.4266 16.8408Z"
        fill="white"
      />
    </svg>
  );
};

export default VolumeMute;
