import React from "react";

import { theme } from "ovou-ui";

import { Flex, Text } from "components";

interface IMediaDetailsProps {
  headline?: string;
  desc?: string;
}

const MediaDetails: React.FC<IMediaDetailsProps> = ({ headline, desc }) => {
  return (
    <Flex direction="column" justify="flex-end" h="100%" zIndex="2" p="6">
      {headline && (
        <Text
          fontSize="24px"
          color={theme.palette.brand.primary.white}
          fontWeight="800"
          zIndex={2}
        >
          {headline}
        </Text>
      )}
      {desc && (
        <Text
          fontSize="14px"
          color={theme.palette.brand.primary.white}
          fontWeight="400"
          zIndex={2}
        >
          {desc}
        </Text>
      )}
    </Flex>
  );
};

export default MediaDetails;
