import React from "react";

import { BusinessForm } from ".";
import { Button as OldButton, Flex, Icon, PageSetup } from "../../components";
import { MAIN_SITE, TEXTS } from "../../constants";
import { useUserState } from "../../context";
import { FEATURES } from "constants/features";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

const Business = () => {
  const { currentProfile } = useUserState();
  const track = useTracking();

  return (
    <PageSetup
      title={TEXTS.routes.BUSINESS}
      subTitle={TEXTS.routes.BUSINESS_SUB_TITLE}
      feature={FEATURES.COMPANY_PROFILE}
      rightBodyElement={
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${MAIN_SITE}/${currentProfile?.handle}`}
        >
          <OldButton
            size="xs"
            variant="white"
            uppercase
            className="ml-3 p-1"
            onClick={() => {
              track({
                eventName: ANALYTICS_EVENTS.COMPANY_PROFILE_PUBLIC_VIEW,
              });
            }}
          >
            <Flex alignItems="center">
              <Icon name="eye" className="mr-2 text-current" />
              {TEXTS.common.PUBLIC_VIEW}
            </Flex>
          </OldButton>
        </a>
      }
    >
      <div className="bg-white p-5 sm:py-10 sm:px-12 rounded-xl shadow-sm mb-5">
        <BusinessForm />
      </div>
    </PageSetup>
  );
};

export default Business;
