import {
    ChangeEventHandler,
    FocusEventHandler,
    ReactEventHandler,
} from "react";

import { useBoolean } from "@chakra-ui/hooks";

interface IUseInputParams {
    onChange?: ChangeEventHandler;
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
    onSelect?: ReactEventHandler;
    maxLength?: number;
}

interface IUseInputReturn {
    handleChange: ChangeEventHandler;
    handleFocus: ChangeEventHandler;
    handleBlur: ChangeEventHandler;
    isActive: boolean;
    isFocused: boolean;
}

export const useInput = ({
    onChange,
    onFocus,
    onBlur,
    maxLength = Infinity,
}: IUseInputParams): IUseInputReturn => {
    const [isActive, { on: setIsActive, off: setIsNotActive }] =
        useBoolean(false);
    const [isFocused, { on: setIsFocused, off: setIsNotFocused }] =
        useBoolean(false);

    const handleChange: ChangeEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = e => {
        e.persist();
        const { value } = e.target;
        if (value.length > maxLength) {
            return;
        }
        onChange?.(e);
        if (value) {
            setIsActive();
        } else {
            setIsNotActive();
        }
    };

    const handleFocus: FocusEventHandler = e => {
        e.persist();
        onFocus?.(e);
        setIsFocused();
    };

    const handleBlur: FocusEventHandler = e => {
        e.persist();
        onBlur?.(e);
        setIsNotFocused();
    };

    return {
        handleChange,
        handleFocus,
        handleBlur,
        isActive,
        isFocused,
    };
};
