import React, { FC } from "react";

import Module from "../../components/Module";
import { Flex } from "../../components";
import {
  DISCOVERY_MODULE,
  DISCOVERY_MODULE_CONTENT,
  MODULE_COVER,
  targetType,
} from "configs/discoveryModule";
import { is } from "date-fns/locale";
import { IModuleProps } from "configs/setupModules";

const DiscoveryModule: FC<IModuleProps> = ({
  onNext,
  onClose,
  target,
  isReturning,
}) => {
  return (
    <Flex
      maxWidth="xl"
      mx="auto"
      width="100%"
      height="100%"
      flexDirection="column"
      position="relative"
    >
      <Module
        moduleContent={DISCOVERY_MODULE_CONTENT}
        moduleCover={MODULE_COVER}
        onNext={onNext}
        onClose={onClose}
        showCloseBtn={DISCOVERY_MODULE.showCloseBtn}
        moduleName={DISCOVERY_MODULE.moduleName}
        target={target}
        isReturning={Boolean(isReturning)}
      />
    </Flex>
  );
};

export default DiscoveryModule;
