import React, { useState } from "react";

import { FastField } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import Contact from "./Contact";
import { isItemNewlyAddedItem } from "./ContactInformation";
import { Icon, SelectFormField, TextFormField } from "../../../components";
import { Flex } from "../../../components/Warpper";
import { VALIDATION_MESSAGES } from "../../../constants";
import { useDeleteIndividualQuery } from "../../../features/individualProfile/queries";
import { SECTION_EMPTY_FIELD_VALUE } from "../editContactInformationPage";

const LocationItem = ({
  location,
  index,
  addressOptions,
  handleRemoveItem,
  onLocationChange,
}) => {
  const [selectedValue] = useState(() => {
    return addressOptions?.find(item => item.value === location?.field_type_id);
  });
  return (
    <Flex flexDirection="column" position="relative" margin="45px 0 0 0">
      <div
        style={{
          position: "absolute",
          top: "-25px",
          right: "8px",
          zIndex: "10",
          cursor: "pointer",
        }}
      >
        <Icon
          name="close"
          width={"20px"}
          onClick={() => handleRemoveItem(location, index)}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: "-25px",
          zIndex: "10",
          cursor: "pointer",
        }}
      >
        <p className="text-sm font-medium text-custom-gray-150">Address type</p>
      </div>
      <FastField
        name={`locations.${index}.field_type_id`}
        options={addressOptions}
        component={SelectFormField}
        label="Address type"
        placeholder="Location"
        handleOnChange={() => onLocationChange(index)}
        rightIcon="close"
        widthIcon="20"
        isSearchable={false}
      />

      {location.field_type_id !== "location" && (
        <>
          <FastField
            name={`locations.${index}.street1`}
            label="Street line one"
            component={TextFormField}
            autoComplete="address-line1"
          />
          <FastField
            name={`locations.${index}.street2`}
            label="Street line two"
            component={TextFormField}
            autoComplete="address-line2"
          />
        </>
      )}
      <FastField
        name={`locations.${index}.city`}
        label="City"
        component={TextFormField}
        autoComplete="address-level2"
      />

      <FastField
        name={`locations.${index}.state`}
        label="State / Province"
        component={TextFormField}
        autoComplete="address-level1"
      />

      {location.field_type_id !== "location" && (
        <FastField
          name={`locations.${index}.postalCode`}
          label="Postal Code"
          component={TextFormField}
          autoComplete="postal-code"
        />
      )}
      <FastField
        name={`locations.${index}.country`}
        label="Country"
        component={TextFormField}
        autoComplete="country-name"
      />
    </Flex>
  );
};

export const locationValidationSchema = Yup.array().of(
  Yup.object()
    .shape({
      field_type_id: Yup.string().required(
        VALIDATION_MESSAGES.IS_REQUIRED("Location"),
      ),
      value: Yup.string(),
      id: Yup.string().nullable(),
      order: Yup.number(),
    })
    .optional(),
);

const Location = props => {
  const { locations, options, push, form } = props;
  const { addToast } = useToasts();
  const { profileHandle } = useParams();

  const addressOptions = [
    {
      base_value: null,
      icon: "/media/field_type/icon/location.svg",
      title: "Location",
      label: "Location",
      parentId: "address",
      value: "location",
      id: "location",
      value_placeholder: "link here",
      walkthrough: null,
    },
    {
      id: "office",
      base_value: null,
      icon: "/media/field_type/icon/location-1.svg",
      title: "Office",
      label: "Office",
      parentId: "address",
      value: "office",
      value_placeholder: "link here",
      walkthrough: null,
    },
    {
      id: "store",
      base_value: null,
      icon: "/media/field_type/icon/store.svg",
      title: "Store",
      label: "Store",
      parentId: "address",
      value: "store",
      value_placeholder: "link here",
      walkthrough: null,
    },
  ];

  const { mutate: deleteIndividual } = useDeleteIndividualQuery({
    type: "generic",
    onSuccess: () => {
      addToast("Location Removed Successfully", {
        appearance: "success",
      });
    },
  });

  const handleAddNewField = e => {
    e.preventDefault();
    push({
      ...SECTION_EMPTY_FIELD_VALUE,
      field_type_id: "location",
      id: `new-${locations.length + 1}`,
      order: locations.length + 1,
      city: "",
      country: "",
      postalCode: "",
      state: "",
      street1: "",
      street2: "",
      value: ";;;;;;",
    });
  };

  const handleRemove = (locationToDelete, index) => {
    const reorderItems = () =>
      form.setValues(values => ({
        ...values,
        locations: locations
          .filter(location => location.id !== locationToDelete.id)
          .map((location, i) => ({ ...location, order: i })),
      }));

    if (
      locationToDelete.id !== null &&
      !isItemNewlyAddedItem(locationToDelete)
    ) {
      deleteIndividual(
        {
          handle: profileHandle,
          category: "field",
          id: locationToDelete.id,
        },
        {
          onSuccess: () => {
            reorderItems();
          },
        },
      );
    } else {
      reorderItems();
    }
  };

  const handleLocationChange = index => {
    const fieldValue = form.values.locations?.[index];
    const isLocation = fieldValue?.field_type_id === "location";
    if (!isLocation) {
      form.setFieldValue(`locations.${index}.street1`, "");
      form.setFieldValue(`locations.${index}.street2`, "");
      form.setFieldValue(`locations.${index}.postalCode`, "");
    }
  };

  return (
    <Contact
      title="Address"
      label="Location"
      handleAddNewField={handleAddNewField}
    >
      {locations.map((location, index) => (
        <LocationItem
          className="mb-6"
          key={`locations.${index}`}
          name={`locations.${index}`}
          label="location"
          location={location}
          index={index}
          handleRemoveItem={handleRemove}
          addressOptions={options || addressOptions}
          selectedLocation={location?.field_type_id}
          onLocationChange={handleLocationChange}
        />
      ))}
    </Contact>
  );
};

export default Location;
