import { MANAGE_USERS_ROUTE } from "../constants";
import { Onboarding } from "features";
import DiscoveryModule from "features/discovery-modules";

import { targetType } from "./discoveryModule";

export interface IModuleProps {
  onNext: () => void;
  onClose?: () => void;
  target: targetType;
  handleBackModule?: () => void;
  isReturning?: boolean;
}

interface IModule {
  id: number;
  component: React.ComponentType<IModuleProps>;
  target: string | number;
  isActive: boolean;
}

export const SETUP_MODULES: IModule[] = [
  {
    id: 2,
    component: Onboarding,
    target: MANAGE_USERS_ROUTE,
    isActive: true,
  },

  {
    id: 1,
    component: DiscoveryModule,
    target: 2,
    isActive: true,
  },
];
