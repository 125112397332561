import React from "react";

import { FastField } from "formik";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import Contact from "./Contact";
import { TextFormField } from "../../../components";
import { useDeleteIndividualQuery } from "../../../features/individualProfile/queries";
import { SECTION_EMPTY_FIELD_VALUE } from "../editContactInformationPage";
import { urlLinkRegex } from "utils";

export const websiteValidationSchema = Yup.array().of(
  Yup.object()
    .shape({
      field_type_id: Yup.string(),
      value: Yup.string().matches(urlLinkRegex, "Website format is wrong"),
      // .url(VALIDATION_MESSAGES.FORMAT_IS_WRONG("Website")),
      // .required(VALIDATION_MESSAGES.IS_REQUIRED("Website")),
      id: Yup.string().nullable(),
      order: Yup.number(),
    })
    .optional(),
);

const Website = props => {
  const { websites, remove, push } = props;

  const { profileHandle } = useParams();
  const { addToast } = useToasts();

  const handleAddNewField = e => {
    e.preventDefault();
    push({
      ...SECTION_EMPTY_FIELD_VALUE,
      field_type_id: "website",
      id: `new-${websites.length + 1}`,
      order: websites.length + 1,
    });
  };

  const { mutate: deleteIndividual } = useDeleteIndividualQuery({
    type: "generic",
    onSuccess: () => {
      addToast("Website Removed Successfully", {
        appearance: "success",
      });
    },
  });

  const handleRemove = (websiteId, index) => {
    if (websiteId && websiteId.toString().includes("new")) {
      remove(index);
      return;
    }
    deleteIndividual(
      {
        handle: profileHandle,
        category: "field",
        id: websiteId,
      },
      {
        onSuccess: () => {
          remove(index);
        },
      },
    );
  };

  return (
    <Contact
      title="Website"
      label="website"
      handleAddNewField={handleAddNewField}
      handleRemove={handleRemove}
    >
      {websites?.map((website, index) => (
        <FastField
          key={index}
          name={`websites.${index}.value`}
          label="Website Url"
          rightIcon="close"
          widthIcon="20"
          onIconClick={() => handleRemove(website.id, index)}
          component={TextFormField}
        />
      ))}
    </Contact>
  );
};

export default Website;
