import { FC } from "react";

import { Box } from "@chakra-ui/react";

interface IProgressBarProps {
  progress: number;
  isLast: boolean;
}

const ProgressBar: FC<IProgressBarProps> = ({ progress, isLast }) => {
  return (
    <Box
      flex="1"
      h="2px"
      bg="gray"
      position="relative"
      borderRadius="md"
      mr={isLast ? 0 : 2}
    >
      <Box
        h="100%"
        bg="white"
        width={`${progress}%`}
        borderRadius="md"
        transition="width 0.1s ease"
        data-testid="progress-bar"
      />
    </Box>
  );
};

export default ProgressBar;
