import React, { FC, useEffect, useState } from "react";

import { Box, Spinner } from "components";
import Zapier from "./Zapier";
import IntegrationMenu from "./IntegrationMenu";
import { useFlag } from "utils";
import { FEATURE_FLAGS } from "constants/flags";
import useIntegrationService from "./useIntegrationService";

const Integrations: FC = () => {
  const showParagonIntegration = useFlag(FEATURE_FLAGS.PARAGON_INTEGRATION);
  const { integrations } = useIntegrationService();

  return (
    <Box>
      {integrations.length === 0 ? (
        <Box justifyContent="center">
          <Spinner />
        </Box>
      ) : (
        <Box width="50%" sx={{ "@media (max-width: 1200px)": { width: "100%" } }}>
          {showParagonIntegration && (
            <IntegrationMenu integrations={integrations} />
          )}
          <Zapier />
        </Box>
      )}
    </Box>
  );
};

export default Integrations;
