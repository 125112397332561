import api from "api";
import { QueryConfig } from "react-query/types/core/types";
import { useQuery } from "react-query";
import { MAIN_SITE } from "../../../constants";

const getAuthSessionQuery = async () => {
  const response = await api.get("/api/auth/session", {
    baseURL: MAIN_SITE,
    headers: {
      Authorization: undefined,
    },
    withCredentials: true,
  });
  return response.data;
};

export interface AuthSession {
  token?: {
    access?: string;
    refresh?: string;
  };
  user: {
    id: string;
    email: string;
  };
  provider?: string;
  expires?: string;
}

export const useAuthSessionQuery = ({
  onSuccess,
}: Pick<QueryConfig<AuthSession>, "onSuccess">) => {
  return useQuery<AuthSession>(["auth/session"], getAuthSessionQuery, {
    onSuccess,
  });
};
