import React, { useEffect, useState } from "react";
import { PAGES } from "../../../constants/pages";
import { removeToken, setToken } from "../../../utils";
import { useHistory } from "react-router-dom";
import {
  setProfile,
  setTeam,
  usePermissionsState,
  useUserDispatch,
} from "../../../context";
import {
  useMyTeamsQuery,
  useProfileDataQuery,
} from "../../../features/auth/queries";
import { IProfileResponse } from "../../../types/Profile";
import { ITeamResponse } from "../../../types/Team";
import { useAuthSessionQuery } from "./useAuthSessionQuery";
import { Loader } from "../../../components/Spinner";
import { isNeedsWizard } from "utils/userStatus";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useUserDispatch();
  const { status: userStatus } = usePermissionsState();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const { data: businessProfilesData, getProfileData } = useProfileDataQuery({
    onSuccess: (data: IProfileResponse) => {
      dispatch(setProfile(data?.profiles?.[0]));
    },
  });

  const {
    getMyTeams,
    data: teamsData,
    isSuccess: isTeamsDataSuccess,
  } = useMyTeamsQuery({
    onSuccess: (data: ITeamResponse) => {
      const team = data?.teams?.[0];
      dispatch(setTeam(team));
    },
  });
  useEffect(() => {
    if (!isTeamsDataSuccess || !shouldRedirect || !userStatus) return;

    const teamsLength = teamsData?.teams?.length;
    const team = teamsData?.teams?.[0];

    if (!Boolean(teamsLength)) {
      removeToken();
      window.location.href = PAGES.SIGN_IN;
    } else if (teamsLength === 1 && isNeedsWizard(team?.wizard_step)) {
      history.push(PAGES.ONBOARDING);
    } else {
      history.push(PAGES.MANAGE_USERS);
    }

    setShouldRedirect(false);
  }, [
    history,
    isTeamsDataSuccess,
    teamsData,
    businessProfilesData,
    shouldRedirect,
    userStatus,
  ]);

  useAuthSessionQuery({
    onSuccess: async data => {
      if (data?.token?.access) {
        setToken(data.token.access, data.token.refresh);
        await Promise.all([getProfileData(), getMyTeams()]);
        setShouldRedirect(true);
      } else {
        window.location.href = PAGES.SIGN_IN;
      }
    },
  });

  return <Loader fullScreen />;
};

export default LoginPage;
