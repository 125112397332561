import { useState, useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { setProfile, setTeam, useUserDispatch } from "../context";
import { useMyTeamsQuery, useProfileDataQuery } from "../features/auth/queries";
import { removeToken, setToken } from "../utils";
import { useAuthSessionQuery } from "../pages/auth/login/useAuthSessionQuery";
import { ACCESS_TOKEN_KEY } from "../constants";
import { PAGES } from "../constants/pages";
const useValidateToken = () => {
  const dispatch = useUserDispatch();
  const history = useHistory();
  const [isValidating, setIsValidating] = useState(true);

  const { getProfileData } = useProfileDataQuery({
    onSuccess: data => {
      dispatch(setProfile(data?.profiles?.[0]));
    },
  });

  //  add this logic since we keep access token on state too and when logout from another window we still have access token in state
  const handleStorageChange = useCallback(event => {
    if (event.storageArea !== localStorage || event.key !== ACCESS_TOKEN_KEY) {
      return;
    }
    if (event.key === ACCESS_TOKEN_KEY) {
      const newToken = event.newValue;
      if (!newToken) {
        history.replace("/auth/logout", { notOwner: true });
        return;
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [handleStorageChange]);

  const { getMyTeams } = useMyTeamsQuery({
    onSuccess: data => {
      const team = data?.teams?.[0];
      if (!team) {
        removeToken();
        history.replace("/auth/logout", { notOwner: true });
        return;
      }
      dispatch(setTeam(team));
    },
  });

  useAuthSessionQuery({
    onSuccess: async data => {
      if (data?.token?.access) {
        setToken(data.token.access, data.token.refresh);
        await Promise.all([getProfileData(), getMyTeams()]);
        setIsValidating(false);
      } else {
        window.location.href = PAGES.SIGN_IN;
      }
    },
  });

  return isValidating;
};
export default useValidateToken;
