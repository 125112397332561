import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";

import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/modal";
import { FiCopy, FiEye, FiEyeOff } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import { Button, useClipboard, useBoolean } from "@chakra-ui/react";
import { theme, Zapier as ZapierIcon } from "ovou-ui";

import { useGetAccountQuery } from "../../hooks";
import { Text, Box, Flex, Loader } from "components";
import { ZAPIER_TEXTS } from "../../constants/Texts";
import { ZAPIER_CLIENT_ID } from "../../constants";
import { useProfileDataQuery } from "features/auth/queries";
import { useFlag } from "utils";
import { FEATURE_FLAGS } from "constants/flags";
import TextInput from "../../components/Input/TextInput";
import { useMediaLayout } from "use-media";

const ZapierFeature: FC = () => {
  const isMobile = useMediaLayout({ maxWidth: "768px" });
  const embedZapierEnabled = useFlag(FEATURE_FLAGS.EMBED_ZAPIER);
  const { status, data: { api_key: apiKey } = {} } = useGetAccountQuery();
  const GREY1 = theme.palette.ui.greys.grey1;
  const { hasCopied, onCopy, setValue, value } = useClipboard(apiKey || "");
  const [showPasswordInputValue, { toggle: togglePasswordInputValue }] =
    useBoolean(false);
  const [
    showEmbeddedZapier,
    { on: enableEmbeddedZapier, off: disableEmbeddedZapier },
  ] = useBoolean(false);
  const { addToast } = useToasts();

  useEffect(() => {
    if (apiKey) setValue(apiKey);
  }, [apiKey, setValue]);

  const onClickCopy = useCallback(() => {
    addToast("Copied the API key.", {
      appearance: "success",
    });
    onCopy();
  }, [addToast, onCopy]);

  const maskedKey = useMemo(() => {
    const firstPart = value.slice(0, 50).replace(/./g, "*");
    const lastPart = value.slice(-5);
    return `${firstPart}${lastPart}`;
  }, [value]);

  if (status !== "success") {
    return null;
  }

  return (
    <>
      <Box
        borderColor={theme.palette.ui.greys.lightGrey3}
        borderRadius="md"
        borderWidth="thin"
        padding="4"
        margin="45px 0 24px"
      >
        <Flex alignItems="center">
          <ZapierIcon />
          <Text fontSize={21} fontWeight="800" ml="16px">
            Zapier
          </Text>
        </Flex>
        <Text color={GREY1} fontSize={14} marginTop="4">
          {ZAPIER_TEXTS.configure.desc}
        </Text>
        <Box margin="24px 0">
          {embedZapierEnabled && (
            <Button width="100%" onClick={enableEmbeddedZapier}>
              Configure
            </Button>
          )}
        </Box>
        {embedZapierEnabled && (
          <Text color={GREY1} fontSize={14}>
            {ZAPIER_TEXTS.apiKey.desc}
          </Text>
        )}
        <Box marginTop="4">
          <TextInput
            name="api-key"
            id="api-key"
            isReadOnly
            value={
              showPasswordInputValue
                ? value
                : Array(value.length).fill("*").join("")
            }
            label="API Key"
            rightElement={
              <Box>
                <button
                  onClick={togglePasswordInputValue}
                  style={{ paddingLeft: "5px", paddingRight: "5px" }}
                >
                  {showPasswordInputValue ? <FiEyeOff /> : <FiEye />}
                </button>
                <button
                  onClick={onClickCopy}
                  style={{
                    marginLeft: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  <FiCopy color={theme.palette.brand.primary.orange} />
                </button>
              </Box>
            }
          />
        </Box>
      </Box>
      <Modal
        isOpen={showEmbeddedZapier}
        onClose={disableEmbeddedZapier}
        size={isMobile ? "full" : "4xl"}
        isCentered={!isMobile}
        scrollBehavior="inside"
        motionPreset={isMobile ? "slideInBottom" : "scale"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent style={isMobile ? undefined : { bottom: "auto", borderRadius: "0.75rem" }}>
          <ModalCloseButton style={{ zIndex: 1000 }} />
          <ModalBody paddingTop="8">
            <EmbeddedZapier />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ZapierFeature;

const EmbeddedZapier: FC = () => {
  const { status: zapierJsStatus } = useZapierJS();
  const { status: zapierCssStatus } = useZapierCSS();
  const { status, data: { email } = {} } = useGetAccountQuery();
  const { data: { profiles } = { profiles: [] } } = useProfileDataQuery({
    state: "representative",
  }, true);

  const signUpFirstName = profiles.length > 0 ? profiles[0].first_name : "your_first_name";
  const signUpLastName = profiles.length > 0 ? profiles[0].last_name : "your_last_name";

  return status === "success" &&
    zapierJsStatus === "success" &&
    zapierCssStatus === "success" ? (
    <Box>
      <zapier-workflow
        sign-up-email={email}
        sign-up-first-name={signUpFirstName}
        sign-up-last-name={signUpLastName}
        client-id={ZAPIER_CLIENT_ID}
        theme="light"
        intro-copy-display="show"
        manage-zaps-display="show"
        guess-zap-display="hide"
        app-search-bar-display="show"
        template-limit={10}
        template-cta-display="show"
        zap-create-from-scratch-display="hide"
      />
    </Box>
  ) : <Loader />
}

const useZapierJS = () => {
  return useQuery(["zapier", "js"], () => {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "module";
      script.src = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
      script.onload = function () {
        resolve();
      }
      script.onerror = function () {
        reject();
      }
      document.head.appendChild(script);
    });
  }, { refetchOnWindowFocus: false });
}

const useZapierCSS = () => {
  return useQuery(["zapier", "css"], () => {
    return new Promise<void>((resolve, reject) => {
      const stylesheet = document.createElement("link");
      stylesheet.rel = "stylesheet";
      stylesheet.href = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
      stylesheet.onload = function () {
        resolve();
      }
      stylesheet.onerror = function () {
        reject();
      }
      document.head.appendChild(stylesheet);
    });
  }, { refetchOnWindowFocus: false });
}