export const TEXTS = {
  menuItems: {
    MANAGE_USERS: "Manage Users",
    PROFILES: "Profiles",
    CONTACTS: "Contacts",
    ANALYTICS: "Analytics",
    GROUPS: "Groups",
    SETTINGS: "Settings",
    COMPANY_PROFILE: "Company Profile",
    MANAGE_DEVICES: "Manage Cards",
    INTEGRATIONS: "Integrations",
    TUTORIALS: "Tutorials",
    ORDER_MORE_CARDS: "Order More Cards",
    UPGRADE_NOW: "Upgrade Now",
    MANAGE_SUBSCRIPTION: "Manage Subscription",
    MY_OVOU_PROFILE: "My OVOU Profile",
    LOG_OUT: "Log out",
  },
  routes: {
    MANAGE_USERS: "Manage Users",
    MANAGE_USERS_SUB_TITLE:
      "Here, you can manage users and their roles in your team. You can also set up or edit your team member's profiles.",
    CARDS: "Manage Profiles",
    DIRECTORY: "Group",
    DIRECTORY_SUB_TITLE: "Users can only be part of one group.",
    SETTINGS: "Settings",
    SETTINGS_SUB_TITLE:
      "Changes on this page effect all profiles in your team.",
    BUSINESS: "Company Profile",
    BUSINESS_SUB_TITLE:
      "Your OVOU company profile showcases your business and not a particular team member. It also allows you to apply changes easily to all profiles within your Team.",
    MY_OVOU: "My OVOU Profile",
    ORDER_MORE_CARDS: "Order more cards",
    MANAGE_SUBSCRIPTION: "Manage Subscription",
    CHANGE_PASSWORD: "Change Password",
    TUTORIALS: "Tutorials",
    TUTORIALS_SUB_TITLE:
      "Please watch the following videos to help you get the most out of your OVOU cards.",
    INTEGRATIONS: "Integrations",
    INTEGRATIONS_SUB_TITLE:
      "Unlock the full potential of OVOU with our integrations.",
  },
  common: {
    LOGOUT: "log out",
    SIGN_IN: "sign in",
    SEARCH: "Search",
    UNASSIGN_CARD: "unassign card",
    ASSIGN_CARD: "Assign Card",
    INVITE_USERS: "Invite Users",
    UNASSIGN: "unassign",
    CANCEL: "cancel",
    PUBLIC_VIEW: "Public View",
  },
  signInPage: {
    TITLE: "Welcome!",
    SUB_TITLE: "Sign in to Dashboard",
    BUTTON: "sign in",
    FORGOT_PASSWORD: "Forgot password?",
    DONT_HAVE_OVOU_CARD: "Don't have OVOU Card?",
  },
  forgotPasswordPage: {
    TITLE: "Forgot Password",
    BUTTON: "Send Verification Email",
  },
  resetPasswordPage: {
    TITLE: "Reset Password",
    BUTTON: "Reset",
  },
  assignCardModal: {
    TITLE: "Assign Card",
    BUTTON: "Assign Card",
    INVITE_USERS: "Invite Users",
    EDITING_TITLE: "Change invite Email",
    EDITING_BUTTON: "Send New Invitation",
  },
  unassignCardModal: {
    TITLE: "Are you sure you want to unassign the card?",
    BUTTON_YES: "Yes, I'm sure",
    BUTTON_NO: "No, Thanks",
  },
  createEditGroupModal: {
    CREATE_TITLE: "Create New Group",
    EDIT_TITLE: "Edit Group",
    CREATE_BUTTON: "Create",
    EDIT_BUTTON: "Edit",
  },
  REACH_LIMIT: "You have reached the limit",
  NO_AVAILABLE_SEAT:
    "You don't have any available Seats. Please add Seats before you can invite new users.",
  NO_AVAILABLE_CARDS:
    "You do not have any available Cards. Please add more cards before you can invite new users.",
  USERS: "Users",
  SEATS: "Seats",
};

export const ANALYTICS_TEXT = {
  STATS_PAGE: {
    TITLE: "Analytics",
    SUB_TITLE:
      "Get insights into how your team is utilizing OVOU to get leads.",
  },
  EMPTY_STATE: {
    TITLE: "Analytics data is not available for the chosen date range.",
    SUB_TITLE:
      "Please try a different range or wait for more data to be collected.",
    INTERACTIONS:
      "No interactions have been occurring within your team recently",
  },
  POPOVER: {
    TITLE: "Sources",
    SUB_TITLE:
      "Different ways people discovered your profile, including physical card, QR codes, email signature, and more.",
  },
};

export const DEVICES_TEXTS = {
  DEVICES_PAGE: {
    TITLE: "Manage Cards",
    SUB_TITLE:
      "Here you can manage your team devices. You can assign or unassign devices to your team member profiles.",
  },
  EMPTY_STATE: {
    TITLE: "Check your email",
    SUB_TITLE:
      "Once you have followed the instructions in the email, our team will create mockups for you to review and confirm. We then process your order shortly after.",
  },
};

export const CONTACTS_TEXTS = {
  CONTACTS_PAGE: {
    TITLE: "Contacts",
    SUB_TITLE:
      "Here you can view contacts generated by your team. You can also create tags to better organize new leads.",
  },
  EMPTY_STATE: {
    TITLE: "Sorry, not enough data to display Contacts yet",
    SUB_TITLE:
      "We need a little more time, please use your OVOU cards and your profiles more.",
  },
};

export const ZAPIER_TEXTS = {
  configure: {
    desc: "Connect OVOU with thousands of popular apps through Zapier to streamline your work and save time, all without coding.",
  },
  apiKey: {
    desc: 'The API key is the secondary authentication method. It is not needed if you configure your Zapier integrations using the "Configure" option above.',
  },
  subscription: {
    TITLE: "Zapier",
    SUB_TITLE:
      "Connect OVOU with thousands of popular apps through Zapier to streamline your work and save time, all without coding.",
    desc: "This is your API Key to copy and paste into Zapier.",
  },
  legacy: {
    TITLE: "Zapier",
    SUB_TITLE: "Send leads to over 1,200 CRMs via Zapier",
    desc: "OVOU Team subscription let’s you build powerful automation for the new leads you generate by connecting your account to over 5,000 apps via Zapier.",
  },
};

export const HAS_ENTER = /\n/g;
