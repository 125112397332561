import { FC, ReactNode } from "react";

import { Box, HStack } from "@chakra-ui/layout";
import { FormLabel, Fade } from "@chakra-ui/react";

interface ILabelProps {
    label: string;
    id?: string;
    isActive?: boolean;
    isInvalid?: boolean;
    isFloating?: boolean;
    isFocused?: boolean;
    isDisabled?: boolean;
    subLabel?: ReactNode;
}

export const Label: FC<ILabelProps> = ({
    label,
    isActive,
    isInvalid,
    id,
    isFloating,
    isFocused,
    subLabel,
    isDisabled,
}) => {
    const baseLabelStyles = {
        position: "absolute",
        m: 0,
        top: -1,
        color: isDisabled
            ? "brand.gray.300"
            : isInvalid
                ? "brand.red.500"
                : isFocused
                    ? "brand.primary.500"
                    : "brand.gray.400",
    };

    const floatingStyles = {
        ...baseLabelStyles,
        top: 5,
        transform: isActive ? "translate(0, -21px) scale(0.85)" : "none",
        transition: "all 0.1s ease-out",
        transformOrigin: "top left",
    };

    return (
        <HStack justifyContent="space-between" alignItems="center">
            <FormLabel
                fontSize="md"
                sx={isFloating ? floatingStyles : baseLabelStyles}
                htmlFor={id}
            >
                {label}
            </FormLabel>
            <Fade in={!!(subLabel && isActive)} unmountOnExit>
                <Box sx={{ position: "absolute", right: 0, top: -1, fontSize: "sm" }}>
                    {subLabel}
                </Box>
            </Fade>
        </HStack>
    );
};
