import React from "react";

import { theme } from "ovou-ui";

import { Box, Text, CButton as Button, Flex } from "components";
import GradientOverlay from "../../components/GradientOverlay";
import { TModuleCover } from "configs/discoveryModule";

type ModuleCoverProps = TModuleCover & {
  handlePrimaryButton: () => void;
  handleSecondaryButton: () => void;
  blobUrl: string;
  as: string;
  poster: string;
};

const ModuleCover: React.FC<ModuleCoverProps> = ({
  poster,
  blobUrl,
  coverImage,
  coverVideo,
  headline,
  gradient,
  primary,
  secondary,
  handlePrimaryButton,
  handleSecondaryButton,
}) => {
  return (
    <Box
      width="100%"
      h="100dvh"
      position="relative"
      backgroundImage={coverImage ? `url(${blobUrl})` : ""}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
    >
      {coverVideo && (
        <video
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
          autoPlay
          playsInline
          loop
          muted={true}
          src={blobUrl}
          data-testid="cover-video"
          poster={poster}
          preload="auto"
        />
      )}

      {/* Gradient Overlay */}
      <GradientOverlay height="304px" gradient={gradient} />

      {/* Content */}
      <Flex
        direction="column"
        justify="flex-end"
        align="center"
        h="100%"
        zIndex="2"
        p="6"
      >
        {/* Headline */}
        {headline && (
          <Text
            fontSize="24px"
            color={theme.palette.brand.primary.white}
            mb="32px"
            fontWeight="800"
            zIndex={2}
          >
            {headline}
          </Text>
        )}

        {/* Primary Action Button */}
        {primary && (
          <Button
            mb="24px"
            zIndex={2}
            onClick={handlePrimaryButton}
            width="100%"
          >
            {primary}
          </Button>
        )}

        {/* Secondary Action Link/Button */}
        {secondary && (
          <Text
            color={theme.palette.brand.primary.orange}
            fontSize="14px"
            fontWeight="800"
            zIndex={2}
            onClick={handleSecondaryButton}
            cursor="pointer"
          >
            {secondary}
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default ModuleCover;
