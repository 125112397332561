import { MAIN_SITE } from "constants/index";

const MY_PROFILE = "/my-profile";
export const PAGES = {
  MANAGE_USERS: "/manage-users",
  UPGRADE: "/upgrade",
  PROFILE_SETUP: `${MAIN_SITE}/onboarding`,
  ONBOARDING: "/onboarding",
  RESET_PASSWORD: "/auth/reset-password",
  SIGN_IN: `${MAIN_SITE}/login`,
  LOGOUT: "/auth/logout",
  MY_PROFILE,
  EDIT_CARD_DETAILS: `${MY_PROFILE}/edit-card-details`,
};
