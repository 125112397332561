import React from "react";

import { IconType } from "react-icons/lib";
import { MdClose } from "react-icons/md";

const Close: IconType = props => {
  return <MdClose {...props} />;
};

export default Close;
